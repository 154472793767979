<template>
  <div>
    <div class="d-flex flex-wrap">
      <ProgramCalendarDay
        v-for="(day, dayIndex) in days"
        :key="`key-${dayIndex}`"
        :day="day"
        :dayLabel="day.diff(dayOne, 'day')"
        class="daycol"
      ></ProgramCalendarDay>
    </div>

    <div class="mx-2 my-5 text-center">
      <button @click="addWeek" class="btn btn-link">Add another week</button>
    </div>

    <portal to="modal">
      <transition name="modal">
        <ActionBar
          @actionBarClearSelection="actionClearSelection"
          @actionBarCopySelection="actionCopySelection"
          @actionBarDeleteSelection="actionDeleteSelection"
          v-if="programGetSelectedSessions.length"
          :items="programGetSelectedSessions"
        ></ActionBar>
      </transition>
    </portal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const initialDays = (dayOne) => {
  const days = [];
  while (days.length < 28) {
    days.push(dayOne.add(days.length, 'day'));
  }
  return days;
};

export default {
  name: 'ProgramCalendar',
  components: {
    ProgramCalendarDay: () => import('@/components/program/ProgramCalendarDay'),
    ActionBar: () => import('@/elements/ActionBar'),
  },
  computed: {
    ...mapGetters('program', ['programGetSessionsSortedByDate', 'programGetSelectedSessions']),
  },
  watch: {
    programGetSessionsSortedByDate() {
      this.checkWeek();
    },
  },
  methods: {
    async deleteSessions() {
      this.$store.dispatch('program/setBusy', true);
      try {
        await this.$store.dispatch('program/deleteSessions');
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.$store.dispatch('program/setBusy', false);
      }
    },
    addWeek() {
      const lastDay = this.days[this.days.length - 1];
      for (let i = 0; i < 7; i += 1) {
        this.days.push(lastDay.add(i + 1, 'day'));
      }
    },
    checkWeek() {
      const { days, programGetSessionsSortedByDate } = this;
      const sessions = programGetSessionsSortedByDate;
      if (sessions.length) {
        const lastSession = sessions[sessions.length - 1];
        const lastSessionDay = dayjs(lastSession.date);
        while (lastSessionDay.isAfter(days[days.length - 1], 'day')) {
          this.addWeek();
        }
      }
    },
    actionClearSelection() {
      this.$store.dispatch('program/clearSelection');
    },
    actionCopySelection() {
      const sessions = this.programGetSelectedSessions;
      this.$store.dispatch('session/addSessionsToClipboard', sessions);
      this.actionClearSelection();
    },
    actionDeleteSelection() {
      this.deleteSessions();
    },
  },
  data() {
    return {
      dayOne: dayjs('01-01-2000', 'DD-MM-YYYY'),
      days: [],
    };
  },
  mounted() {
    this.days = initialDays(this.dayOne);
    this.checkWeek();
  },
};
</script>

<style lang="scss" scoped>
.daycol {
  border-left: 1px solid rgba(0, 0, 0, .1);
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  min-height: 15rem;

  &:nth-child(7n+1) {
    border-left: 0;
  }

  &:nth-child(-n+7) {
    border-top: 0;
  }
}
</style>
